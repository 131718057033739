@font-face {
    font-family: "Phenomena-Bold";
    src: url("/static/fonts/phenomena-bold.eot");
    src: url("/static/fonts/phenomena-bold.woff") format("woff"),
    url("/static/fonts/phenomena-bold.woff2") format("woff2"),
    url("/static/fonts/phenomena-bold.otf") format("opentype") 
};

@import url('https://fonts.googleapis.com/css2?family=Jost:wght@400;600;700&display=swap');

$fontPrimary: 'Jost', Helvetica, sans-serif;
$fontSecondary: 'Jost', Helvetica, sans-serif;
$browser-context: 16px;
$body: (
        "fontSize": 16px,
        "lineHeight": 24px,
        "fontWeight": 300
);

$h1: (
        "fontSize": 50px,
        "lineHeight": 50px
);

$h1Mobile: (
        "fontSize": 30px,
        "lineHeight": 30px
);

$h2: (
        "fontSize": 40px,
        "lineHeight": 40px
);

$h2Mobile: (
        "fontSize": 28px,
        "lineHeight": 30px
);

$h3: (
        "fontSize": 28px,
        "lineHeight": 32px
);

$h3Mobile: (
        "fontSize": 24px,
        "lineHeight": 30px
);

$h4: (
        "fontSize": 24px,
        "lineHeight": 30px
);

$h4Mobile: (
        "fontSize": 20px,
        "lineHeight": 20px
);

$h5: (
        "fontSize": 16px,
        "lineHeight": 18px
);

$p: (
        "fontSize": 20px,
        "lineHeight": 32px
);

$pMobile: (
        "fontSize": 20px,
        "lineHeight": 28px
);
