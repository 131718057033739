.block-image {
  .block-image-container {
    .image-container {
      .text-container {
        .title {
          font-size: 28px;
          line-height: 32px;
        }
      }
    }
  }
}