.block-book-now {
  .title-container {
    background: var(--block-book--title-background);
    .title {
      color: var(--block-book--title-colour);
    }
  }
  .dropdown-container {
    background: var(--block-book--dropdown-background);
    .dropdown-wrapper {
      .dropdown {
        .dropdown-select{
          .input-text {
            color: var(--dropdown-select--bookdatepicker-placeholder-colour);
          }
        }
        .dropdown-title {
          color: var(--block-book--dropdown-title-colour);
        }
      }
      .btn-block-book-now {
        background: var(--block-book--button-background);
      }
    }
  }
}