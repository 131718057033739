.block-locations {
  .dropdown-select {
    border: 1px solid var(--dropdown-select--venue-selector-border);
    border-radius: 0;
  }
  .list-venues {
    .venue {
      padding: 36px 41px;
    }
  }
}