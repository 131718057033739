.block-filtered-package {
  .title-container {
    .container {
      .text-container {
        margin-right: 30px;
      }
    }
  }
  .image-container {
    .title {
      max-width: 90%;
    }
  }
  .package-modal {
    button.btn {
      background: var(--primary-2);
      color: var(--neutral-white);
    }
  }
}
