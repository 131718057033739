.blog-section {
  .column-left {
    .blog-content {
        .back {
          left: 50%;
          transform: translateX(-50%);
          font-weight: 400;
          font-size: 20px;
          line-height: 32px;
        }
    }
  }
}