.component-footer {
  .footer-top {
    .footer-top-container {
      .footer-top-section {
        .navigation-menu {
          .navigation-menu-container {
            .column {
              .navigation-items {
                .item {
                  font-size: 18px;
                  line-height: 22px;
                }
              }
            }
          }
        }
      }
    }
  }
  .footer-bottom {
    .footer-bottom-container {
      .footer-bottom-section {
        a,
        .bottom-item {
          color: var(--neutral-black);
        }
      }
    }
  }
}