.block-featured-article {
  .blocks-container {
    .item {
      .hover-details {
        @include screen-md-max {
          padding: 27px 15px;
        }
        .hover-details-container {
          .details-container {
            @include screen-md-max {
              top: 65%;
            }
            .heading {
              font-size: 28px;
              line-height: 32px;
              margin-bottom: 5px;
              text-transform: none;
              @include screen-md-max {
                margin-bottom: -2px;
              }
            }
            .subheading {
              font-size: 20px;
              line-height: 32px;
              text-transform: none;
            }
          }
        }
      }
      .spare-background {
        @include screen-md-max {
          height: 17px;
        }
      }
    }
  }
}