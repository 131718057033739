.block-cta-list {
  .cta-list-item {
    .header {
      .heading {
        font-weight: 600;
        text-transform: none;
      }
    }
    .body {
      font-size: 20px;
      line-height: 32px;
    }
    .button-container {
      .more-info {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #0645AD;
        text-transform: none;
      }
    }
  }
}