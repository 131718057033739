.block-faq {
  h2 {
    font-size: 28px;
    line-height: 32px;
  }
  .section {
    .section-top {
    }
    .section-bottom {
      .faq-section {
        .faq-section-container {
          .questions {  
            .button-back-top {
              color: #0645AD;
            }
          }
        }
      }
    }
  }
}


