.dropdown-select {
  height: 62px;
  .input-text {
    border: none;
    height: 62px;
  }
  .venue-selector {
    .states-container {
      ul {
        li {
          font-size: 16px;
        }
      }
    }
    .venues-container {
      .venues {
        .venue {
          font-size: 16px;
        }
      }
    }
  }
}

.venue-selector-mobile.show {
  .venues-container {
    .venues {
      .venue {
        font-weight: 700;
      }
    }
  }
}