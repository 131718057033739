.template-blogs-landing {
  .blog-content {
    padding: 28px 29px;
    @include screen-md-max {
      padding: 23px 12px;
    }
    .blog-properties {
      .date {
        font-size: 16px;
        line-height: 20px;
      }
      
      .tag {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
  .blog-featured {
    @include screen-lg-max {
      margin-bottom: 30px;
    }
  }
}
