﻿/**
Functionality with an animation or spare that needs updating.
- Deals List
- Featured Article
 */

:root {
  // Used for all icons that are svg and have a class of .icon
  --icon-colour: var(--primary-2);

  // Buttons
  --button-small-box-shadow: none;
  --button-small-padding: 10px 24px;

  // Links
  --link--colour: var(--primary-1);
  --link--colour-hover: var(--primary-1);

  // App Cookies
  --component-cookies-background: var(--primary-3);
  --component-cookies-link-colour: var(--neutral-white);
  --component-cookies-text-colour: var(--neutral-white);
  
  // App Body Container
  --app-body-container--padding-top: 80px;
  --app-body-container--padding-top-screen-xl-max: 80px;
  --app-body-container--padding-top-screen-lg-max: 55px;
  --app-body-container--padding-top-screen-md-max: 55px;

  // Header
  --header-background: var(--primary-3);
  --header-colour: var(--neutral-white);
  --header-divider-background: var(--neutral-white);
  --header-category-hover-colour: var(--primary-1);
  --header-category-item-hover-colour: var(--primary-1);
  --header-logo-container-width: 185px;
  --header-category-item-hover-weight: 0.1em;
  --header-navigation-category-font-weight: 600;
  --header-category-item-font-weight: 600;
  --header-category-item-bullet-width: 10px;
  --header-navigation-category-font-size: 16px;
  --header-button-items-background: var(--primary-2);
  --header-button-items-text-colour: var(--neutral-white);

  // Footer
  --footer-background: var(--primary-3);
  --footer-colour: var(--neutral-white);
  --footer-desktop-border-top: var(--neutral-white);
  --footer-top-section-heading-colour: var(--primary-1);
  --footer-bottom-background: var(--primary-1);
  --footer-items-font-weight: 700;
  --footer-logo-other-brands-desktop-width: 165px;
  --footer-logo-other-brands-mobile-width: unset;
  --footer-logo-other-brands-mobile-height: 22px;
  --footer-logo-other-brands-flex-direction: column-reverse;
  --footer-logo-other-brands-margin-bottom-first: unset;
  --footer-logo-other-brands-margin-bottom-last: 25px;

  // --------- ITEMS -----------------------------------------------------------------------------------------------
  --item-contact-item--background: var(--primary-1);
  --item-contact-item--color: var(--neutral-white);

  // --------- SECTIONS -----------------------------------------------------------------------------------------------

  // Content Section
  --section-content--background: var(--neutral-white);

  // Faq Section
  --section-faq--border-bottom: var(--neutral-white);

  // Blog Section
  --section-blog--related-banner-color: var(--primary-3);
  --section-blog--related-banner-title-color: var(--neutral-white);
  // --section-blog--tag-color: var(--primary-2);
  --section-blog--tag-color: var(--neutral-black);
  --section-template-blogs-page-banner-font-size: 18px;
  --section-template-blogs-page-banner-font-size-mobile: 14px;
  --section-template-blogs-page-banner-line-height: 32px;
  --section-template-blogs-page-banner-line-height-mobile: 28px;
  --section-template-blogs-page-banner-font-family: $fontSecondary;
  --section-blog--title-font-family: $fontSecondary;
  --section-blog--title-font-size: 28px;
  --section-blog--title-line-height: 32px;
  --section-blog-title-font-weight-desktop: 700;
  --section-blog-title-font-weight-mobile: 700;
  --section-blog--share-button-font: $fontSecondary;
  --section-blog--remove-filter-color: var(--primary-1);
  
  // Blog Landing
  --section-blog-landing--pagination-active-background-color: var(--primary-2);
  --section-blog-landing--pagination-active-text-color: var(--neutral-white);
  --section-blog-landing--pagination-next-image: url(/static/img/zonebowling-pagination-next.svg);
  // --------- PARTIALS -----------------------------------------------------------------------------------------------

  // Dropdown Select
  --dropdown-select--background: var(--neutral-white);
  --dropdown-select--input-text-colour: var(--neutral-2);
  --dropdown-select--input-text-border: var(--primary-3);
  --dropdown-select--input-text-active-colour: var(--neutral-2);
  --dropdown-select--venue-selector-background: var(--neutral-white);
  --dropdown-select--venue-selector-border: var(--primary-3);
  --dropdown-select--states-border-bottom: var(--neutra-white);
  --dropdown-select--states-list-colour: var(--neutral-2);
  --dropdown-select--states-list-active-colour: var(--primary-1);
  --dropdown-select--venues--hover-colour: var(--neutral-white);
  --dropdown-select--venues-colour: var(--neutral-black);
  --dropdown-select--colour: var(--neutral-2);
  --dropdown-select--input-date-background: transparent;
  --dropdown-select--input-date-color: var(--neutral-2);
  --dropdown-select--states-list-active-font-weight: 700;
  --dropdown-select--states-list-active-text-color: var(--neutral-black);
  --dropdown-select--venues-hover-bgcolor: var(--primary-3);
  --dropdown-select--venues-container-padding: 0;
  --dropdown-select--venues-container-ul-padding: 0;

  // BookDatePicker
  --dropdown-select--bookdatepicker-border: var(--primary-1);
  --dropdown-select--bookdatepicker-colour: var(--neutral-2);
  --dropdown-select--bookdatepicker-background: var(--neutral-white);
  --dropdown-select--bookdatepicker-placeholder-colour: var(--neutral-black);
  --dropdown-select--bookdatepicker-border-radius: 10px;
  --dropdown-select--bookdatepicker-height: 60px;
  --dropdown-select--bookdatepicker-height-mobile: 44px;
  --dropdown-select--bookdatepicker-: var();

  // Flatpicker Calendar
  // --dropdown-select--flatpickr-calendar-border: var(--primary-2);
  --dropdown-select--flatpickr-calendar-border: var(--primary-1);
  --dropdown-select--flatpickr-months-border: var(--primary-1);
  --dropdown-select--flatpickr-weekday-colour: var(--primary-1);
  --dropdown-select--flatpickr-weekday-font-weight: 700;
  --dropdown-select--flatpickr-day-font-weight: 700;
  --dropdown-select--flatpickr-day-selected-background: var(--primary-2);
  --dropdown-select--flatpickr-day-selected-colour: var(--neutral-white);
  --dropdown-select--flatpickr-day-selected-border: var(--primary-2);
  --dropdown-select--flatpickr-day-today-border-colour: transparent;
  --dropdown-select--flatpickr-day-today-background-colour: var(--primary-1);
  --dropdown-select--flatpickr-day-today-colour: var(--neutral-white);
  --dropdown-select--flatpickr-months-svg-fill: var(--primary-2);
  --dropdown-select--: var(--primary-2);

  // Package Tile
  --package-tile--background: var(--neutral-white);
  --package-tile--header-background: var(--primary-1);
  --package-tile--heading-colour: var(--neutral-white);
  --package-tile--button-border-top: var(--neutral-black);
  --package-tile--ul-margin-bottom-desktop: 20px;
  --package-tile--ul-margin-bottom-mobile: 10px;

  // Promo Modal
  --promo-modal--image-background: var(--primary-1);
  --promo-modal--details-background: var(--neutral-black);
  --promo-modal--details-colour: var(--neutral-white);

  // --------- BLOCKS (MODULES) ---------------------------------------------------------------------------------------

  // Image Module
  --block-block-image--title-display: none;
  --block-block-image--text-container-display: block;
  --block-block-image--image-container-height: auto;
  --block-block-image--image-height: 100%;
  --block-block-image--image-position-mobile: relative;
  --block-block-image--image-top: 0;
  --block-block-image--image-left: 0;

  // Activity Details
  --block-activity-details--heading-colour: var(--neutral-white);
  --block-activity-details--swiper-pagination-bullet-active: var(--neutral-black);
  --block-activity-details-heading-text-transform: none;
  
  // Activity Pricing
  --block-activity-pricing--options-container-background: var(--neutral-white);
  --block-activity-pricing--options-container-colour: var(--neutral-black);
  --block-activity-pricing--options-container-border-bottom-colour: var(--neutral-black);
  --block-activity-pricing--title-background: var(--primary-1);
  --block-activity-pricing--title-colour: var(--neutral-white);
  --block-activity-pricing--please-select-background: var(--neutral-white);
  --block-activity-pricing--text-block-colour: var(--neutral-white);
  --block-activity-pricing--bonus-text-border-top: var(--neutral-3);
  --block-activity-pricing--cta-container-border-top: var(--neutral-3);
  --block-activity-pricing--swiper-pagination-bullet-active: var(--neutral-black);
  --block-activity-pricing--border-colour: var(--neutral-3);

  // Book
  --block-book--title-background: var(--primary-1);
  --block-book--title-colour: var(--primary-3);
  --block-book--dropdown-background: var(--primary-3);
  --block-book--dropdown-title-colour: var(--neutral-white);
  --block-book--dropdown-title-text-transform: capitalize;
  --block-book--dropdown-title-font-weight: 700;
  --block-book--dropdown-title-font-size: 18px;
  --block-book--dropdown-title-font-mobile: 16px;
  --block-book--venue-selector-mobile: var(--neutral-white);
  --block-book--venue-selector-mobile-states-border: var(--neutral-white);
  --block-book--venue-selector-mobile-states-colour: var(--neutral-2);
  --block-book--venue-selector-mobile-states-colour-active: var(--neutral-black);
  --block-book--venue-selector-mobile-states-border-active: var(--primary-1);
  --block-book--venue-li-hover-colour: var(--primary-3);
  --block-book--venue-colour: var(--neutral-black);
  --block-book--button-background: var(--primary-2);

  // Contact
  --block-contact--left-background: var(--primary-3);
  --block-contact--left-colour: var(--neutral-white);
  --block-contact--right-background: var(--neutral-white);
  --block-contact--phone-email-colour: var(--neutral-white);
  --block-contact--phone-email-text-decoration: underline;

  // Content
  --block-content--border: var(--neutral-3);

  // Content Sections
  --block-content-sections--background: var(--neutral-white);

  // CTA Grid
  --block-cta-grid--block-background: var(--primary-1);
  --block-cta-grid--block-heading-colour: var(--neutral-white);
  --block-cta-grid--border: var(--neutral-3);
  --block-cta-grid--heading-text-transform: none;
  --block-cta-grid--heading-font-weight: 700;

  // CTA List
  --block-cta-list--item-background: var(--neutral-white);
  --block-cta-list--heading-colour: var(--neutral-white);
  --block-cta-list--border: var(--neutral-3);

  // Deals List
  --block-deals--block-background: var(--primary-1);
  --block-deals--details-colour: var(--neutral-white);
  --block-deals--divider-background: var(--neutral-white);
  --block-deals--heading-colour: var(--neutral-white);
  --block-deals--swiper-pagination-bullet-active: var(--neutral-black);
  --block-deals--modal-details-background: var(--neutral-black);
  --block-deals--modal-details-colour: var(--neutral-white);
  --block-deals--border: var(--neutral-3);
  --block-deals--spare-background: var(--primary-1);
  --block-deals--spare-overlay-background: rgb(0, 0, 0, 0.5);
  --block-deals--heading-font-family: $fontSecondary;
  --block-deals--heading-text-transform: none;
  --block-deals--details-container-top: 65%;
  --block-deals--subheading-font-weight: 400;
  --block-deals-cta-background-color: var(--primary-2);
  --block-deals-cta-text-color: var(--neutral-white);
  --block-deals--heading-font-size: 26px;
  --block-deals--heading-line-height: 30px;
  --block-deals--subheading-font-size: 18px;
  --block-deals--subheading-line-height: 32px;
  --block-deals-carousel-details-container-padding: 25px 25px 40px;
  --block-deals--modal-button-background: var(--neutral-white);
  --block-deals--modal-button-text-color: var(--neutral-black);
  
  // Error 404
  --block-error-404--text-colour: var(--neutral-white);

  // FAQ Module
  --block-faq--section-top-background: var(--primary-3);
  --block-faq--section-top-colour: var(--neutral-white);
  --block-faq--section-bottom-background: var(--neutral-white);
  --block-faq-section--jump-categories-justify-content: center;
  --block-faq-section--jump-categories-max-width: 100%;
  --block-faq-section--jump-container-text-align: center;
  --block-faq-section--jump-text-colon-mobile-display: none;
  --block-faq-section--back-to-top-mobile-color: var(--hyperlink);
  --block-faq-section--category-color: var(--neutral-white);
  --block-faq-section--category-hover-color: var(--neutral-white);
  --block-faq-section--category-text-transform: none;

  // Featured Article
  --block-featured-article--block-background: var(--primary-1);
  --block-featured-article--hover-details-container-colour: var(--neutral-white);
  --block-featured-article--divider-background: var(--neutral-white);
  --block-featured-article--border: var(--neutral-3);
  --block-featured-article--heading: var(--neutral-white);
  --block-featured-article--spare-background: var(--primary-1);
  --block-featured-article--button-background: var(--primary-2);
  --block-featured-article--button-text: var(--neutral-white);

  // Filtered Package
  --block-filtered-package--title-background: var(--primary-1);
  --block-filtered-package--title-colour: var(--primary-3);
  --block-filtered-package--filter-background: var(--primary-1);
  --block-filtered-package--filter-title-colour: var(--neutral-white);
  --block-filtered-package--image-title-colour: var(--neutral-white);
  --block-filtered-package--image-title-font-weight: 700;
  --block-filtered-package--please-select-background: var(--neutral-white);
  --block-filtered-package--swiper-slide-background: var(--neutral-white);
  --block-filtered-package--details-bottom-border-top: var(--neutral-3);
  --block-filtered-package--swiper-pagination-bullet-active: var(--neutral-black);
  --block-filtered-package--package-modal-details-background: var(--neutral-black);
  --block-filtered-package--package-modal-details-colour: var(--neutral-white);
  --block-filtered-package--border: var(--neutral-3);
  --block-filtered-package--text-font-weight: 700;

  // Food Drinks Menu
  --block-food-drinks--tab-colour: var(--neutral-2);
  --block-food-drinks--tab-active-colour: var(--neutral-black);
  --block-food-drinks--tab-active-border-bottom: var(--primary-2);
  --block-food-drinks--column-border-top: var(--neutral-3);
  --block-food-drinks--menu-section-border-bottom: var(--neutral-2);
  --block-food-drinks--menu-title-font-size: 24px;
  --block-food-drinks--menu-title-line-height: 30px;
  --block-food-drinks--menu-title-mobile-font-size: 18px;
  --block-food-drinks--menu-title-mobile-line-height: 30px;
  --block-food-drinks--menu-item-name-font-size: 16px;
  --block-food-drinks--menu-item-name-line-height: 20px;
  --block-food-drinks--menu-item-price-font-size: 16px;
  --block-food-drinks--menu-item-price-line-height: 20px;
  --block-food-drinks--menu-item-subtext-font-size: 20px;
  --block-food-drinks--menu-item-subtext-line-height: 32px;

  // Gallery
  --block-gallery--image-background: var(--neutral-white);
  --block-gallery--swiper-pagination-bullet-active: var(--neutral-black);
  --block-gallery--border: var(--neutral-3);

  // Image


  // Intro CTA
  --block-intro-cta--: var();
  --block-intro-cta--border: var(--neutral-3);
  --block-intro-cta--section-right-text-transform: none;
  --block-intro-cta--section-right-font-size-mobile: 18px;

  // Intro Package
  --block-intro-package--title-colour: var(--neutral-white);
  --block-intro-package--cta-container-border-top: var(--neutral-black);
  --block-intro-package--deals-modal-background: var(--neutral-black);
  --block-intro-package--deals-modal-colour: var(--neutral-white);
  --block-intro-package--title-text-transform: none;
  --block-intro-package--container-flex-direction-mobile: column;
  --block-intro-package--package-tile-container-margin-bottom: 0;
  --block-intro-package--body-container-margin-bottom: 20px;
  --block-intro-package--title-font-weight: 600;

  // Intro Pricing
  --block-intro-pricing--pricing-title-background: var(--primary-2);
  --block-intro-pricing--pricing-title-colour: var(--neutral-white);
  --block-intro-pricing--locations-dropdown-container-background: var(--primary-1);
  --block-intro-pricing--details-container-border: var(--neutral-black);
  --block-intro-pricing--please-select-active-background: var(--neutral-white);
  --block-intro-pricing--border: var(--neutral-3);

  // Intro Quote
  --block-intro-quote--border: var(--neutral-3);

  // Locations
  --block-locations--state-colour: var(--neutral-2);
  --block-locations--state-active-colour: var(--neutral-black);
  --block-locations--state-active-text-decoration-colour: var(--primary-1);
  --block-locations--border: var(--neutral-3);
  --block-locations--venue-suburb: var(--primary-3);
  --block-locations--tooltip-bg-color: var(--neutral-3);
  --block-locations--tooltip-text-color: var(--neutral-white);
  --block-locations--state-font-size-desktop: 40px;
  --block-locations--state-font-size-mobile: 28px;
  --block-locations--venue-suburb-text-transform: none;
  --block-locations--list-states-padding:  0 0 33px;

  // Locations Venue
  --block-locations-venue--heading-text-transform: capitalize;

  // Menu Single
  --block-menu-single--border: var(--neutral-2);
  --block-menu-single--title-text-transform: capitalize;
  --block-menu-single--top-external-pdf-display: none;
  --block-menu-single--external-pdf-mobile-text-color: var(--primary-1);
  --block-menu-single--accordion-title-mobile-color: var(--primary-2);
  --block-menu-single--name-font-size: 24px;
  --block-menu-single--name-line-height: 30px;
  --block-menu-single--name-font-size-mobile: 16px;
  --block-menu-single--name-line-height-mobile: 20px;
  
  // Mini FAQ
  --block-mini-faq--border: var(--neutral-3);
  --block-mini-faq--font-size-desktop: 26px;
  --block-mini-faq--line-height-desktop: 32px;
  --block-mini-faq--font-family: 'Jost', 'Helvetica', sans-serif;
  --block-mini-faq--text-transform: none;

  // Package
  --block-package--swiper-slider-background: var(--neutral-white);
  --block-package--header-background: var(--primary-1);
  --block-package--header-colour: var(--neutral-white);
  --block-package--heading-font-weight: 700;
  --block-package--button-border: var(--neutral-black);
  --block-package--features-list-padding-bottom: 20px;
  --block-package--button-padding-top: 20px;
  --block-package--swiper-pagination-bullet-active: var(--neutral-black);
  --block-package--modal-details-background: var(--neutral-black);
  --block-package--modal-details-colour: var(--neutral-white);
  --block-package--border: var(--neutral-3);
  --block-package--heading-text-transform: none;

  // Text Hero
  --block-text-hero--heading-background: var(--primary-1);
  --block-text-hero--heading-colour: var(--neutral-white);
  --block-text-hero--opacity-overlay-background: var(--neutral-black);
  --block-text-hero--border: var(--neutral-3);
  --block-text-hero--height: 247px;

  // Venue Tiles
  --block-venue-tiles--venue-border-top: var(--neutral-black);
  --block-venue-tiles--venue-suburb-colour: var(--primary-2);
  --block-venue-tiles--venue-last-child-border: var(--neutral-black);

  // Video Hero
  --block-video--background: var(--neutral-black);
  --block-video--border: var(--neutral-3);

  // Video Block
  --block-block-video--background: var(--neutral-black);
  --block-block-video--border: var(--neutral-3);
  
  // League Scores
  --block-league-scores--title-background: var(--primary-2);
  --block-league-scores--title-colour: var(--neutral-white);
  --block-league-scores--dropdown-background: var(--primary-1);
  --block-league-scores--dropdown-title-colour: var(--neutral-white);
  --block-league-scores--venue-selector-mobile: var(--neutral-white);
  --block-league-scores--venue-selector-mobile-states-border: var(--neutral-white);
  --block-league-scores--venue-selector-mobile-states-colour: var(--neutral-2);
  --block-league-scores--venue-selector-mobile-states-border-active: var(--primary-2);
  --block-league-scores--venue-li-hover-colour: var(--primary-2);
  --block-league-scores--venue-colour: var(--primary-1);

  // Available Locations
  --block-available-locations--state-colour: var(--neutral-2);
  --block-available-locations--state-active-colour: var(--neutral-black);
  --block-available-locations--state-active-text-decoration-colour: var(--primary-1);
  --block-available-locations--border: var(--neutral-2);
  --block-available-locations--venue-suburb: var(--primary-3);
  --block-available-locations--venue-suburb-text-transform: capitalize;

  // Icons Module
  --block-block-icons--link-color: var(--neutral-black);

  // Forms Module
  --block-form-field-error-colour: red;
  --block-form-field-border: 1px solid var(--neutral-black);
  --block-form-field-checkbox-colour: white;
  --block-form-field-checkbox-background: white;
  --block-form-field-checkbox-border: 1px solid var(--neutral-black);
  --block-form-field-checkbox-checked-background: var(--neutral-black);
  --block-form-field-checkbox-checked-colour: white;
  --block-form-field-checkbox-checked-border: 1px solid green;
  --block-form-loader-image: url(/static/img/loader-zonebowling-element.svg);
  --block-form-loader-background: rgba(255,255,255, 0.85);
  --block-form-select-color: var(--neutral-black);
 
}