.component-header {
  .header-container {
    .header-container-wrapper {
      .top {
        .navigation-container {
          .sub-menu-list {
            .sub-menu {
              margin-right: 20px;
              @include screen-xl-max {
                margin-right: 10px;
              }
              .category {
                font-size: 18px;
                line-height: 22px;
              }
            }
            .button-menu {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}

.component-header .mobile-navigation.active {
  .mobile-navigation-items {
    .button-items .item {
      background: var(--header-button-items-background);
    }
  }
}